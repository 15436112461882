.App {
  text-align: center;
  color: #ffffff;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: linear-gradient(135deg, #0a0a0a, #1a1a1a);
  min-height: 100vh;
}

/* Hero Section */
.hero-section {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sacred-geometry {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.sacred-pattern {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 215, 0, 0.1), rgba(255, 107, 0, 0.1));
  transform-origin: center;
  animation: rotatePattern 20s linear infinite;
}

.floating-elements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.floating-symbol {
  position: absolute;
  font-size: 2rem;
  color: rgba(255, 215, 0, 0.2);
  animation: float 6s ease-in-out infinite;
}

.design-showcase {
  position: relative;
  z-index: 2;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(10, 10, 10, 0.8);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 215, 0, 0.1);
}

.showcase-header {
  text-align: center;
  margin-bottom: 3rem;
}

.brand-symbol {
  display: inline-block;
  position: relative;
  margin-bottom: 2rem;
}

.sacred-symbol {
  font-size: 4rem;
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: pulse 2s ease-in-out infinite;
}

.symbol-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 2px solid rgba(255, 215, 0, 0.2);
  border-radius: 50%;
  animation: rotate 10s linear infinite;
}

.showcase-title {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
}

.brand-name {
  display: block;
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  margin-bottom: 1rem;
}

.tagline {
  font-size: 1.8rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2rem;
}

.showcase-subtitle {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
  max-width: 800px;
  margin: 0 auto 2rem;
}

.efficiency-banner {
  display: inline-block;
  padding: 1rem 2rem;
  background: rgba(255, 215, 0, 0.1);
  border-radius: 50px;
  font-size: 1.1rem;
  color: #FFD700;
  margin-top: 2rem;
}

.flame-icon {
  margin: 0 1rem;
}

/* Principles Section */
.principles-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 4rem;
}

.principle-card {
  background: rgba(15, 15, 15, 0.8);
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid rgba(255, 215, 0, 0.1);
  transition: transform 0.3s ease;
}

.principle-card:hover {
  transform: translateY(-5px);
}

.principle-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Efficiency Section */
.efficiency-section {
  padding: 6rem 2rem;
  background: rgba(5, 5, 5, 0.95);
  position: relative;
  z-index: 2;
}

.efficiency-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 3rem auto 0;
}

.efficiency-card {
  background: rgba(15, 15, 15, 0.8);
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid rgba(255, 215, 0, 0.1);
  transition: transform 0.3s ease;
}

.efficiency-card:hover {
  transform: translateY(-5px);
}

.efficiency-icon {
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

.efficiency-stats {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #FFD700;
}

/* Design Showcase */
.showcase-content {
  padding: 6rem 2rem;
  background: rgba(10, 10, 10, 0.95);
  position: relative;
  z-index: 2;
}

.category-tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  border-bottom: none;
}

.react-tabs__tab {
  padding: 1rem 2rem;
  background: rgba(15, 15, 15, 0.8);
  border: 1px solid rgba(255, 215, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.react-tabs__tab--selected {
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  color: #000000;
  border: none;
}

.designs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.design-preview {
  background: rgba(15, 15, 15, 0.8);
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgba(255, 215, 0, 0.1);
  transition: transform 0.3s ease;
}

.design-preview:hover {
  transform: translateY(-5px);
}

.preview-header {
  padding: 2rem;
  background-position: center;
  background-size: cover;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.preview-header h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.theme-badge {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: rgba(255, 215, 0, 0.2);
  border-radius: 20px;
  font-size: 0.9rem;
  color: #FFD700;
}

.preview-content {
  padding: 2rem;
}

.color-palette {
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;
}

.color-swatch {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.feature-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin-top: 1.5rem;
}

.feature-tag {
  padding: 0.5rem 1rem;
  background: rgba(255, 215, 0, 0.1);
  border-radius: 20px;
  font-size: 0.9rem;
  color: #FFD700;
  transition: all 0.3s ease;
}

.feature-tag:hover {
  background: rgba(255, 215, 0, 0.2);
  transform: translateY(-2px);
}

/* Animations */
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

@keyframes rotate {
  from { transform: translate(-50%, -50%) rotate(0deg); }
  to { transform: translate(-50%, -50%) rotate(360deg); }
}

@keyframes rotatePattern {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .showcase-title {
    font-size: 2.5rem;
  }
  
  .tagline {
    font-size: 1.4rem;
  }
  
  .designs-grid {
    grid-template-columns: 1fr;
  }
  
  .category-tabs {
    flex-wrap: wrap;
  }
  
  .react-tabs__tab {
    width: calc(50% - 0.5rem);
  }
}

/* Interactive Background */
.interactive-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

/* Contact Section */
.contact-section {
  position: relative;
  z-index: 2;
  background: rgba(5, 5, 5, 0.95);
  padding: 4rem 1rem;
}

/* Footer Styles */
.site-footer {
  position: relative;
  z-index: 2;
  background: rgba(5, 5, 5, 0.95);
  padding: 4rem 2rem 2rem;
  border-top: 1px solid rgba(255, 215, 0, 0.1);
}

.footer-content {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1.5fr;
  gap: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgba(255, 215, 0, 0.1);
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.footer-section.brand {
  text-align: left;
}

.footer-logo {
  margin-bottom: 1rem;
}

.footer-logo .sacred-symbol {
  font-size: 3rem;
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.footer-section h3 {
  font-size: 1.8rem;
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
}

.footer-section h4 {
  font-size: 1.2rem;
  color: #FFD700;
  margin-bottom: 1rem;
}

.footer-tagline {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.6;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.footer-section ul li {
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease;
}

.footer-section ul li a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-section ul li a:hover {
  color: #FFD700;
  transform: translateX(5px);
}

.footer-section.contact .contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-section.contact p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
}

.footer-section.contact .contact-icon {
  font-size: 1.2rem;
  color: #FFD700;
}

.footer-section .social-links {
  display: flex;
  gap: 1rem;
}

.footer-section .social-icon {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(255, 215, 0, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  background: rgba(10, 10, 10, 0.5);
}

.footer-section .social-icon:hover {
  color: #FFD700;
  border-color: rgba(255, 215, 0, 0.3);
  transform: translateY(-2px);
  background: rgba(20, 20, 20, 0.8);
}

.footer-bottom {
  padding-top: 2rem;
  text-align: center;
}

.copyright {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
}

/* Responsive Footer */
@media (max-width: 1024px) {
  .footer-content {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  
  .footer-section.brand {
    grid-column: 1 / -1;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .site-footer {
    padding: 3rem 1.5rem 1.5rem;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 2rem;
    text-align: center;
  }

  .footer-section.contact .contact-info {
    align-items: center;
  }

  .footer-section .social-links {
    justify-content: center;
  }
}

.neural-particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.particle {
  position: absolute;
  background: radial-gradient(circle at center, rgba(0, 255, 136, 0.8), rgba(0, 163, 255, 0.4));
  border-radius: 50%;
  transition: all 0.5s ease;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.parallax-container {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.hero-content {
  position: relative;
  z-index: 2;
}

.main-title {
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: 2rem;
  background: linear-gradient(120deg, #00ff88, #00a3ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(0, 255, 136, 0.3);
}

.role-carousel {
  font-size: 1.8rem;
  color: #ffffff;
  opacity: 0.9;
  margin-bottom: 3rem;
}

/* Scroll Indicator */
.scroll-indicator {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
}

.mouse {
  width: 30px;
  height: 50px;
  border: 2px solid rgba(255, 215, 0, 0.2);
  border-radius: 15px;
  position: relative;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.1);
}

.wheel {
  width: 4px;
  height: 8px;
  background: #FFD700;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  animation: scroll 1.5s infinite;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
}

.scroll-text {
  margin-top: 10px;
  font-size: 0.9rem;
  opacity: 0.7;
}

@keyframes scroll {
  0% { transform: translate(-50%, 0); opacity: 1; }
  100% { transform: translate(-50%, 15px); opacity: 0; }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0) translateX(-50%); }
  40% { transform: translateY(-10px) translateX(-50%); }
  60% { transform: translateY(-5px) translateX(-50%); }
}

/* Skills Section */
.skills-section {
  padding: 4rem 2rem;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  margin: 2rem;
  box-shadow: 0 8px 32px rgba(0, 255, 136, 0.1);
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 3rem;
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(255, 215, 0, 0.2);
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.skill-container {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.skill-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.skill-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.skill-name {
  font-size: 1.2rem;
  color: #ffffff;
}

.skill-tooltip {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.9rem;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  to { opacity: 1; }
}

.skill-bar-bg {
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.skill-bar-fill {
  height: 100%;
  background: linear-gradient(90deg, #00ff88, #00a3ff);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 255, 136, 0.5);
}

/* Projects Section */
.projects-section {
  padding: 4rem 2rem;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  margin: 2rem;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.project-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
  cursor: pointer;
}

.project-card h3 {
  color: #00ff88;
  margin-bottom: 1rem;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.tech-tag {
  background: rgba(0, 255, 136, 0.2);
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #00ff88;
}

/* Neural Network Visualization */
.network-section {
  padding: 4rem 2rem;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  margin: 2rem;
}

.network-visualization {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 400px;
  margin: 2rem auto;
  max-width: 1000px;
  position: relative;
}

.network-layer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.neuron {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: radial-gradient(circle at 30% 30%, #00ff88, #00a3ff);
  box-shadow: 0 0 15px rgba(0, 255, 136, 0.5);
  position: relative;
}

.neuron::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: inherit;
  filter: blur(5px);
  opacity: 0.5;
}

/* Contact Section Styles */
.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  background: rgba(10, 10, 10, 0.8);
  border-radius: 20px;
  padding: 1.5rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 215, 0, 0.1);
}

.contact-info-panel {
  padding: 1.5rem;
}

.contact-title {
  font-size: 2.5rem;
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
}

.contact-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
}

.contact-icon {
  font-size: 1.5rem;
  color: #FFD700;
  filter: drop-shadow(0 0 5px rgba(255, 215, 0, 0.3));
}

.contact-link {
  color: #FFD700;
  text-decoration: none;
  transition: all 0.3s ease;
}

.contact-link:hover {
  color: #FF6B00;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

.social-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.social-link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(255, 215, 0, 0.1);
  border-radius: 8px;
  background: rgba(10, 10, 10, 0.5);
}

.social-link:hover {
  color: #FFD700;
  border-color: rgba(255, 215, 0, 0.3);
  background: rgba(20, 20, 20, 0.8);
  transform: translateY(-2px);
}

.contact-form-panel {
  padding: 1.5rem;
  background: rgba(15, 15, 15, 0.5);
  border-radius: 15px;
  border: 1px solid rgba(255, 215, 0, 0.1);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.form-group label {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
}

.form-input {
  padding: 1rem;
  background: rgba(20, 20, 20, 0.8);
  border: 1px solid rgba(255, 215, 0, 0.1);
  border-radius: 8px;
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-input:focus {
  outline: none;
  border-color: #FFD700;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
}

textarea.form-input {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  color: #000000;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(255, 215, 0, 0.2);
}

/* Responsive Design for Contact Section */
@media (max-width: 768px) {
  .contact-container {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 1.5rem;
  }

  .contact-info-panel,
  .contact-form-panel {
    padding: 1.5rem;
  }

  .contact-title {
    font-size: 2rem;
  }

  .social-links {
    flex-direction: column;
    gap: 1rem;
  }

  .social-link {
    text-align: center;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-title {
    font-size: 2.5rem;
  }
  
  .role-carousel {
    font-size: 1.2rem;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .skills-grid {
    grid-template-columns: 1fr;
  }
  
  .project-grid {
    grid-template-columns: 1fr;
  }
  
  .network-visualization {
    height: 300px;
  }
  
  .neuron {
    width: 15px;
    height: 15px;
  }
}

.font-link {
  font-family: "Tangerine", serif;
  font-style: oblique;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.Name-D{
  font-size: 10 rem;
  height: 50vh ;
  margin: 20%;
}

.Name {
  font-family: "Tangerine"  , serif;
  font-size: 10 rem;
  color: rgb(241, 228, 228);
}

p {
  font-size: 2rem;
  color: rgba(183, 166, 172, 0.32);
}
.App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: rgb(0, 0, 0);
  background-color: rgba(220, 218, 206, 0.55);
}

h1 {
  font-size: 5rem;
  color: rgb(0, 0, 0);
}

.App-link {
  color: rgb(0, 0, 0);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.backg {
  height: 10vh;
}

.content {
  height: 4vh;
  color: #000000;
}

.IAMtext {
  font-size: 2rem;
  color: rgb(0, 0, 0);
  margin-top: 5%;
}

.typed-cursor {
  opacity:0.1;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite; ;
}
typewriter {
  font-size: 10rem;
  color: rgb(0, 0, 0);
  margin-top: 5%;
}

.Navv{
  font-family: Franklin Gothic Medium;
  width: 10vw;
  font-size: calc(8px + 8 * ((100vw - 320px) / 680));
  margin-top : 5vh;
  color: #000000;
  background-color: rgba(0, 0, 0, 0);
}
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.my-tab-list {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: space-around;
  margin-top: calc(100vh / 40); /* 1.25% of the viewport height */
}
.my-tab-list .react-tabs__tab {
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out; /* Adding transition effects */
  margin-right: calc(100vw / 80);
  margin-top: calc(100vh / 80);
  color: black;
}

.my-tab-list .react-tabs__tab--selected {
  border: none;
  color: #8b0031;  /* Color of the selected tab */
}

.my-tab-list .react-tabs__tab:hover {
  transform: scale(1.1); /* Scales the tab size on hover */
  color: red; /* Changes the color on hover */
}

.showcase-header {
  margin-bottom: 1rem;
  padding: 1.5rem;
  background: rgba(5, 5, 5, 0.9);
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 215, 0, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
}

.showcase-title {
  font-size: 3.5rem;
  font-weight: 800;
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

/* Category Tabs */
.category-tabs {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  border: none;
}

.react-tabs__tab {
  padding: 0.8rem 1.5rem;
  background: rgba(10, 10, 10, 0.8);
  border: 1px solid rgba(255, 215, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.react-tabs__tab--selected {
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  color: #000000;
}

/* Design Grid */
.designs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1rem;
  padding: 0.25rem;
}

/* Design Preview Card */
.design-preview {
  background: rgba(10, 10, 10, 0.9);
  border-radius: 15px;
  overflow: hidden;
  backdrop-filter: blur(10px);
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
  position: relative;
}

.design-preview:hover {
  transform: translateY(-5px);
}

.visit-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: linear-gradient(120deg, #FFD700, #FF6B00);
  color: #000000;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  display: none;
}

.design-preview:hover .visit-badge {
  display: block;
}

.preview-header {
  padding: 2rem;
  position: relative;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
